import { Controller } from 'stimulus'
import template from 'lodash.template'

export default class extends Controller {
  static targets = ['filterList', 'reviewList', 'moreButton', 'filterTemplate', 'childTemplate']
  static values = {
    data: Object,
  }

  initialize() {
    this.filterTemplate = template(this.filterTemplateTarget.innerHTML)
    this.createFilterList(this.filterListTarget, this.dataValue)
    this.filterId = 0
    this.pageList = { 0: 1 }
    if (this.dataValue.filters) this.dataValue.filters.forEach((filter) => (this.pageList[filter.id] = 1))
    this.childTemplate = template(this.childTemplateTarget.innerHTML)
    this.renderReviewList(this.dataValue)
  }

  createFilterList(listEl, data) {
    if (!data.filters || !data.reviews) return

    const filtersHtmlString = data.filters
      .map((filter) => {
        const filterCount = data.reviews.filter((review) => review.filtered_review_filter_id === filter.id).length
        const filterHash = {
          filterId: filter.id,
          filterCount: filterCount,
          filterName: filter.name,
        }
        return this.filterTemplate(filterHash)
      })
      .join('')
    listEl.insertAdjacentHTML('beforeend', filtersHtmlString)
  }

  renderReviewList(data, filterId = 0, page = 1) {
    if (page < 0 || !data.reviews) {
      this.moreButtonTarget.classList.add('filtered_review__more_disabled')
      return
    }
    const REVIEWS_ON_FIRST_VIEW = 3
    const PER_PAGE = 5
    const show_review_count = REVIEWS_ON_FIRST_VIEW + PER_PAGE * (page - 1)
    let reviews = data.reviews
    if (filterId === 0) {
      reviews = reviews.sort((a, b) => {
        if ((a.top_pickup && b.top_pickup) || (!a.top_pickup && !b.top_pickup)) {
          return a.id > b.id
        } else {
          return a.top_pickup ? -1 : 1
        }
      })
    } else {
      reviews = reviews.filter((review) => review.filtered_review_filter_id === filterId)
      reviews = reviews.sort((a, b) => {
        if ((a.filter_pickup && b.filter_pickup) || (!a.filter_pickup && !b.filter_pickup)) {
          return a.id > b.id
        } else {
          return a.filter_pickup ? -1 : 1
        }
      })
    }
    const sliced_reviews = reviews.slice(0, show_review_count)
    this.reviewListTarget.innerHTML = sliced_reviews
      .map((review) => {
        const reviewHash = {
          image: data.avatars[review.gender],
          gender: this.genderInJapanese(review.gender),
          age: review.age,
          occupation: review.occupation,
          content_text: review.content_text,
        }
        return this.childTemplate(reviewHash)
      })
      .join('')
    const remaining_review_count = reviews.length - show_review_count
    if (reviews.length > show_review_count) {
      this.moreButtonTarget.textContent = `次の${remaining_review_count >= 5 ? 5 : remaining_review_count}件を開く `
      const arrow_image = document.createElement('img')
      arrow_image.setAttribute('src', data.arrow_path)
      this.moreButtonTarget.appendChild(arrow_image)
      this.moreButtonTarget.classList.remove('filtered_review__more_disabled')
    } else {
      this.moreButtonTarget.classList.add('filtered_review__more_disabled')
    }
  }

  genderInJapanese(gender) {
    if (gender === 'male') {
      return '男性'
    } else if (gender === 'female') {
      return '女性'
    }
  }

  onFilterClick(event) {
    // ボタンのクラス書き換え
    Array.from(this.filterListTarget.children).forEach((child) =>
      child.classList.remove('filtered_review__filter_selected')
    )
    event.currentTarget.classList.add('filtered_review__filter_selected')
    this.filterId = Number(event.currentTarget.dataset.filterId)
    this.page = 1
    this.renderReviewList(this.dataValue, this.filterId, this.pageList[this.filterId])
  }

  onMoreClick() {
    this.pageList[this.filterId] += 1
    this.renderReviewList(this.dataValue, this.filterId, this.pageList[this.filterId])
  }
}
